$bg-color: #102e44;
$primary-color: #fff;
$second-color: #b2fcfb;

$animation-header-start-at: 0s;
$animation-header-duration: 3s;

$animation-menu-start-at: 1.5s;
$animation-menu-duration: 1s;

$animation-main-start-at: .5s;
$animation-main-duration: 2s;

$animation-main-middle-start-at: 1.5s;
$animation-main-middle-duration: 1s;

@mixin keyframes-animation($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}

	@keyframes #{$name} {
		@content;
	}
}

@mixin prefix-property($name, $value) {
	-webkit-#{$name}: $value;
	#{$name}: $value;
}

@include keyframes-animation(fondu) {
	0% {
		@include prefix-property(opacity, 0);
	}

	100% {
		@include prefix-property(opacity, 1);
	}
}

@include keyframes-animation(inTop) {
	0% {
		@include prefix-property(transform, translateY(-58px));
	}

	100% {
		@include prefix-property(transform, translateY(0px));
	}
}

@include keyframes-animation(path) {
	0% {
		@include prefix-property(clip-path, polygon(0 0, 100% 0, 100% 100%, 67% 100%, 33% 100%, 0 100%));
	}

	50% {
		@include prefix-property(clip-path, polygon(0 0, 100% 0, 85% 0, 67% 50%, 33% 50%, 15é% 0));
	}

	100% {
		@include prefix-property(clip-path, polygon(0 0, 100% 0, 67% 0, 67% 0, 33% 0, 33% 0));
	}
}

@include keyframes-animation(content-bottom-to-top) {
	0% {
		@include prefix-property(clip-path, polygon(0 0, 100% 0, 100% 100%, 0 100%));
	}

	100% {
		@include prefix-property(clip-path, polygon(0 0, 100% 0, 100% 0%, 0 0%));
	}
}
@include keyframes-animation(content-top-to-bottom) {
	0% {
		@include prefix-property(clip-path, polygon(0 0, 100% 0, 100% 100%, 0 100%));
	}

	100% {
		@include prefix-property(clip-path, polygon(0 100%, 100% 100%, 100% 100%, 0 100%));
	}
}
@include keyframes-animation(content-path) {
	0% {
		@include prefix-property(clip-path, polygon(0 0, 100% 0, 100% 0%, 0 0%));
	}

	100% {
		@include prefix-property(clip-path, polygon(0 0, 100% 0, 100% 100%, 0 100%));
	}
}
@include keyframes-animation(rotateIcons) {
	0% {
		@include prefix-property(rotate, -15deg);
		// @include prefix-property(scale, 1);
	}
	100% {
		@include prefix-property(rotate, 15deg);
		// @include prefix-property(scale, 1.3);
	}
}

/* Reset CSS */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background-color: $bg-color;

	/* Header styles */
	>header {
		display: flex;
		height: 35vh;
		flex-direction: column;
		align-items: center;
		color: $primary-color;

		nav {
			display: flex;
			width: 100%;
			height: 58px;
			transform: translateY(-58px);
			animation: $animation-menu-duration inTop $animation-menu-start-at;
			animation-fill-mode: both;

			ul {
				display: flex;
				text-align: center;
				align-items: center;
				width: 33%;

				li {
					flex: 1 1 auto;
					list-style: none;
				}
			}

			span {
				display: flex;
				width: 33%;
				justify-content: end;
				padding-right: 20px;
			}

			>ul li {
				a {
					color: $primary-color;
					position: relative;
					text-decoration: none;
					font-weight: bold;
				}

				a:hover {
					color: $primary-color;
					text-decoration: none;
				}

				a:before,
				a:after {
					content: "";
					position: absolute;
					width: 0%;
					height: 2px;
					bottom: -2px;
					background: $primary-color;
				}

				a:before {
					left: 0;
				}

				a:after {
					right: 0;
					background: $primary-color;
					transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
				}

				a:hover:before {
					background: $primary-color;
					width: 100%;
					transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
				}

				a:hover:after {
					background: transparent;
					width: 100%;
					transition: 0s;
				}
			}
			i:hover{
				scale: 1.3;
				animation:.5s linear 0s infinite alternate rotateIcons;
				// animation-duration: 1.3s;
				// animation-name: rotateIcons;
				// animation-iteration-count: infinite;
				// animation-fill-mode: alternate;
			}
		}

		#title {
			display: flex;
			flex-direction: column;
			align-items: center;
			animation: $animation-header-duration fondu $animation-header-start-at;

			h1 {
				font-size: 6vw;
			}

			h2 {
				font-size: 4vw;
				font-weight: normal;
			}
		}

		img {
			width: 150px;
			animation: $animation-header-duration fondu $animation-header-start-at;
		}
	}

	/* Body styles */
	main {
		width: 100vw;
		max-width: 100%;
		height: 65vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.content {
			width: 100%;
			height: 0vh;
			min-height:0vh;
			max-height:0%;
			position: fixed;
			z-index: 15;
			background-color: $bg-color;

			section {
                padding: 0px 20px;
				overflow: hidden;
				min-height:0%;
				max-height: 0px;
				height:0%;
			    background-color: $bg-color;
                transition: min-height 1.2s ease-out,  ;
                line-height:2em;

                h3 {
                    font-size: 1.7em;
                    font-weight: normal;
                    padding-bottom:3vh;
                }
			}
			.active {
				border-top: $second-color double 4px;
				border-bottom: $second-color double 4px;
                min-height: 65vh;
            }
            .close {
                display: flex;
                align-items: end;
                justify-content: end;
                width: 100%;
                padding: 15px 15px 0px 0px;

                i {
                    cursor: pointer;
                }
            }
		}

		.preview {
			display: flex;
			height: 65vh;
			z-index: 10;
			justify-content: space-around;
			background-color: $bg-color;

			section {
				width: 100%;

				h3 {
					height: 0px;
					opacity: 0.2;
					transform: translateY(5vh);
					color: grey;
					text-shadow: 2px 0 $bg-color, -2px 0 $bg-color, 0 2px $bg-color,
						0 -2px $bg-color, 1px 1px $bg-color, -1px -1px $bg-color, 1px -1px $bg-color,
						-1px 1px $bg-color;
				}

				img {
					width: auto;
					max-width: 32vw;
					max-height: 100%;
					opacity: 1;
				}
			}

			section:hover {
				img {
					cursor: pointer;
					opacity: 0.4;
					transition: opacity 250ms;
				}

				h3 {
					opacity: 1;
					color: $primary-color;
					text-shadow: 2px 0 grey, -2px 0 grey, 0 2px grey, 0 -2px grey,
						1px 1px grey, -1px -1px grey, 1px -1px grey, -1px 1px grey;
					transition: opacity 250ms, color 250ms, text-shadow 1.2s;
				}
			}
		}
	}
}

section {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: rgb(218, 218, 218);
}
section[data-content="about"]:before,
section[data-content="history"]:before,
section[data-content="work"]:before {
    content: "";
    position: absolute;
    width: 33vw;
    max-width: 33vw;
    top: 35vh;
    bottom: 0vh;
    background: $bg-color;
    z-index: 1000;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    animation: $animation-main-duration content-bottom-to-top $animation-main-start-at;
    animation-timing-function: linear;
    animation-fill-mode: both;
}
.hide {
    z-index:0 !important;
}