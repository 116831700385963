@keyframes fondu {
  0% {
    -webkit-opacity: 0;
    opacity: 0;
  }

  100% {
    -webkit-opacity: 1;
    opacity: 1;
  }
}

@keyframes inTop {
  0% {
    transform: translateY(-58px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes path {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 67% 100%, 33% 100%, 0 100%);
  }

  50% {
    -webkit-clip-path: polygon(0 0, 100% 0, 85% 0, 67% 50%, 33% 50%, calc(NaN * 1é));
    clip-path: polygon(0 0, 100% 0, 85% 0, 67% 50%, 33% 50%, calc(NaN * 1é));
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 67% 0, 67% 0, 33% 0, 33% 0);
  }
}

@keyframes content-bottom-to-top {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
  }
}

@keyframes content-top-to-bottom {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
}

@keyframes content-path {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes rotateIcons {
  0% {
    -webkit-rotate: -15deg;
    rotate: -15deg;
  }

  100% {
    -webkit-rotate: 15deg;
    rotate: 15deg;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #102e44;
}

body > header {
  height: 35vh;
  color: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
}

body > header nav {
  width: 100%;
  height: 58px;
  animation: 1s 1.5s both inTop;
  display: flex;
  transform: translateY(-58px);
}

body > header nav ul {
  text-align: center;
  width: 33%;
  align-items: center;
  display: flex;
}

body > header nav ul li {
  flex: auto;
  list-style: none;
}

body > header nav span {
  width: 33%;
  justify-content: end;
  padding-right: 20px;
  display: flex;
}

body > header nav > ul li a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  position: relative;
}

body > header nav > ul li a:hover {
  color: #fff;
  text-decoration: none;
}

body > header nav > ul li a:before, body > header nav > ul li a:after {
  content: "";
  width: 0%;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: -2px;
}

body > header nav > ul li a:before {
  left: 0;
}

body > header nav > ul li a:after {
  background: #fff;
  transition: width .8s cubic-bezier(.22, .61, .36, 1);
  right: 0;
}

body > header nav > ul li a:hover:before {
  width: 100%;
  background: #fff;
  transition: width .5s cubic-bezier(.22, .61, .36, 1);
}

body > header nav > ul li a:hover:after {
  width: 100%;
  background: none;
  transition: all;
}

body > header nav i:hover {
  animation: .5s linear infinite alternate rotateIcons;
  scale: 1.3;
}

body > header #title {
  flex-direction: column;
  align-items: center;
  animation: 3s fondu;
  display: flex;
}

body > header #title h1 {
  font-size: 6vw;
}

body > header #title h2 {
  font-size: 4vw;
  font-weight: normal;
}

body > header img {
  width: 150px;
  animation: 3s fondu;
}

body main {
  width: 100vw;
  max-width: 100%;
  height: 65vh;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

body main .content {
  width: 100%;
  height: 0;
  min-height: 0;
  max-height: 0%;
  z-index: 15;
  background-color: #102e44;
  position: fixed;
}

body main .content section {
  min-height: 0%;
  max-height: 0;
  height: 0%;
  background-color: #102e44;
  padding: 0 20px;
  line-height: 2em;
  transition: min-height 1.2s ease-out;
  overflow: hidden;
}

body main .content section h3 {
  padding-bottom: 3vh;
  font-size: 1.7em;
  font-weight: normal;
}

body main .content .active {
  min-height: 65vh;
  border-top: 4px double #b2fcfb;
  border-bottom: 4px double #b2fcfb;
}

body main .content .close {
  width: 100%;
  justify-content: end;
  align-items: end;
  padding: 15px 15px 0 0;
  display: flex;
}

body main .content .close i {
  cursor: pointer;
}

body main .preview {
  height: 65vh;
  z-index: 10;
  background-color: #102e44;
  justify-content: space-around;
  display: flex;
}

body main .preview section {
  width: 100%;
}

body main .preview section h3 {
  height: 0;
  opacity: .2;
  color: gray;
  text-shadow: 2px 0 #102e44, -2px 0 #102e44, 0 2px #102e44, 0 -2px #102e44, 1px 1px #102e44, -1px -1px #102e44, 1px -1px #102e44, -1px 1px #102e44;
  transform: translateY(5vh);
}

body main .preview section img {
  width: auto;
  max-width: 32vw;
  max-height: 100%;
  opacity: 1;
}

body main .preview section:hover img {
  cursor: pointer;
  opacity: .4;
  transition: opacity .25s;
}

body main .preview section:hover h3 {
  opacity: 1;
  color: #fff;
  text-shadow: 2px 0 gray, -2px 0 gray, 0 2px gray, 0 -2px gray, 1px 1px gray, -1px -1px gray, 1px -1px gray, -1px 1px gray;
  transition: opacity .25s, color .25s, text-shadow 1.2s;
}

section {
  color: #dadada;
  flex-direction: column;
  align-items: center;
  display: flex;
}

section[data-content="about"]:before, section[data-content="history"]:before, section[data-content="work"]:before {
  content: "";
  width: 33vw;
  max-width: 33vw;
  z-index: 1000;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  background: #102e44;
  animation: 2s linear .5s both content-bottom-to-top;
  position: absolute;
  top: 35vh;
  bottom: 0;
}

.hide {
  z-index: 0 !important;
}

/*# sourceMappingURL=index.955f7803.css.map */
